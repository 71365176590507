<template>
  <div>

    <section id="knowledge-base-category">
      <b-row class="match-height">
        <b-col
          v-for="(data,index) in categoryList"
          :key="index"
          md="4"
          sm="6"
        >
          <b-card>
            <h6 class="kb-title">
              <feather-icon
                :icon="data.icon"
                size="20"
                class="mr-50"
              />{{ data.titulo }}
            </h6>
            <b-list-group class="list-group-circle mt-2">
              <b-list-group-item
                v-for="(que) in data.tutorialList"
                :key="que.id"
                class="text-body"
                :to="{path: '/tutorial/'+que.link}"
              >
                {{ que.titulo }}
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </b-col>

      </b-row>
    </section>

    <section class="faq-contact">
      <b-row class="mt-2 pt-75 text-center justify-content-center">
        <b-col cols="12">
          <h2>{{ $t('suporte.titulo') }}</h2>
          <b-card-text class="mb-3">
            {{ $t('suporte.subtitulo') }}
          </b-card-text>
        </b-col>

        <!-- Card de contato pelo WhatsApp -->
        <b-col
          v-if="empresa.linkWhatsapp || empresa.suporteTelefone"
          sm="6"
          class="d-flex flex-column justify-content-between"
        >
          <b-card class="shadow-none py-1 faq-contact-card h-100">
            <b-avatar
              v-if="empresa.linkWhatsapp || empresa.suporteTelefone"
              size="42"
              rounded
              variant="light-primary"
              class="mb-2"
              :src="require('@/assets/images/icons/whatsapp.png')"
            />
            <h4 v-if="empresa.suporteTelefone">
              {{ empresa.suporteTelefone }}
            </h4>
            <h4 v-if="empresa.linkWhatsapp">
              <b-link
                v-if="userData"
                :href="encodeURI(empresa.linkWhatsapp.replace('$$nome$', userData.displayName))"
                target="_blank"
              >
                {{ $t('suporte.cliqueaqui') }}
              </b-link>
            </h4>
          </b-card>
        </b-col>

        <!-- Card de contato pelo Telegram -->
        <b-col
          v-if="empresa.suporteTelegram"
          sm="6"
          class="d-flex flex-column justify-content-between"
        >
          <b-card class="shadow-none py-1 faq-contact-card h-100">
            <b-avatar
              size="42"
              rounded
              variant="light-primary"
              class="mb-2"
              :src="require('@/assets/images/icons/telegram.png')"
            />
            <h4>
              @{{ empresa.suporteTelegram }}
            </h4>
            <h4>
              <b-link
                :href="'https://t.me/' + empresa.suporteTelegram"
                target="_blank"
              >
                {{ $t('suporte.cliqueaqui') }}
              </b-link>
            </h4>
          </b-card>
        </b-col>

        <!-- Texto centralizado -->
        <b-col
          cols="12"
          class="text-center"
        >
          <span class="text-body">{{ $t('suporte.textofinal') }}</span>
        </b-col>
      </b-row>
    </section>

  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BListGroup, BListGroupItem, BCardText, BLink, BAvatar,
} from 'bootstrap-vue'
import store from '@/store'
import utilsMixin from '@/mixins/utilsMixin'
import useAppConfig from '@core/app-config/useAppConfig'
import tutorialStoreModule from './tutorialStoreModule'

const TUTORIAL_STORE_MODULE_NAME = 'tutorial'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BListGroup,
    BListGroupItem,
    BCardText,
    BLink,
    BAvatar,
  },

  mixins: [utilsMixin],

  data() {
    return {
      categoryList: [],
    }
  },

  setup() {
    const { empresa } = useAppConfig()
    return {
      empresa,
    }
  },

  created() {
    if (!store.hasModule(TUTORIAL_STORE_MODULE_NAME)) store.registerModule(TUTORIAL_STORE_MODULE_NAME, tutorialStoreModule)
    this.fetchData()
  },

  beforeDestroy() {
    if (store.hasModule(TUTORIAL_STORE_MODULE_NAME)) store.unregisterModule(TUTORIAL_STORE_MODULE_NAME)
  },

  methods: {
    fetchData() {
      store
        .dispatch('tutorial/getCategorias')
        .then(response => {
          this.categoryList = response.data
        })
    },

  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
@import '@core/scss/vue/pages/page-pricing.scss';
</style>

<style lang="scss">
  .pricing-free-trial {
    background-color: rgba(186, 191, 199, 0.12);
    margin-top: 3.5rem;
    margin-bottom: 8rem;
  }
</style>
